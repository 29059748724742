/**
 * Const with params element
 * Check list component
 * Display a check list with the icon check
 */
/* Import section */
import PropTypes from "prop-types"
import React from "react"
import { IconContext } from "react-icons"
import { BsCheck } from "react-icons/bs"
import "../styles/components/checklist.scss"

/* Declaration function */
const Checklist = ({ arrItems, colorCheck}) => {
  return (
    <ul className={`checklist ${colorCheck}`}>
      {arrItems.map((item, index) => (
        <li key={`index-checklist-${index}`}>
          <span className="span-check">
            <IconContext.Provider value={{ className: "icon-check" }}>
              <BsCheck />
            </IconContext.Provider>
          </span>
          <span>{item.textChecklist}</span>
        </li>
      ))}
    </ul>
  )
}

/* Function propTypes declaration */
Checklist.propTypes = {
  colorCheck: PropTypes.string,
}
Checklist.defaultProps = {
  colorCheck: "",
}

/* Export function */
export default Checklist

