/**
 * Const with params element
 * Card plan component
 * Display a card of plan
 */
/* Import section */
import PropTypes from "prop-types"
import React from "react"
import Checklist from "../components/checklist"
import Ctabutton from "../components/ctabutton"
import "../styles/components/cardplan.scss"

/* Declaration function */
const Cardplan = ( { titlePlan, arrListPlan, notePlan } ) => {
  let arrItemsChecklist = []
  arrListPlan.map((item) =>
    arrItemsChecklist.push({textChecklist: item.listDetailPlan})
  )

  return (
    <div className="card-plan card-clickable">
      <h3><span>{titlePlan}</span></h3>
      <Checklist arrItems={arrItemsChecklist}  />
      {notePlan && <div className="note-plan" dangerouslySetInnerHTML={{ __html:notePlan}}/>}
      <div className="box-ctatext-card">
        <Ctabutton ctaLabel="Get Started" ctaLink="/get-started" />
      </div>
      <a href="/get-started/" aria-label="Get Started"><span className="span-cta-card">Get Started</span></a>
    </div>
  )
}

/* Function propTypes declaration */
Cardplan.propTypes = {
  titlePlan: PropTypes.string,
  arrListPlan: PropTypes.array,
  notePlan: PropTypes.string,
}

Cardplan.defaultProps = {
  titlePlan: "Title Card",
  arrListPlan: [],
  notePlan: "",
}


/* Export function */
export default Cardplan
