/**
 * Plans page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Cardplan from "../components/cardplan"
import Herotype1 from "../components/herotype1"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/pages/plans.scss"


/* Page function declaration */
const Planspage = ({data}) => {

  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPagePlans

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroPlans.map(texthero => (
    arrTextHero.push({textHero: texthero.introHeroPlans})
  ))

  //Arry for Details Plan
  let arrItemsPlans = []
  dataAcf.repeaterDetailsPlans.map( (detail)  => (
    arrItemsPlans.push({titlePlan: detail.titleDetailPlan, arrListPlan: detail.repeaterListDetailPlan, notePlan: detail.noteDetailPlan})
  ))

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "getstarted2"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero section */}
      <section className="section-hero-plans top-section">
        <Herotype1 keyHero={dataPage.title} h1title={dataAcf.titleHeroPlans} arrIntro={arrTextHero}  colorBar="blue" />
      </section>

      {/*Plans details section */}
      <section className="section-details-plans">
        <div className="wrap-intro-list wrap-maincontent">
          <Titledecorative title={dataAcf.titleDetailsPlans} />
          <div className="flex-box">
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textDetailsPlans}}/>
          </div>
        </div>
        <div className="wrap-list-plans">
          <div className="grid-list-plans grid-loop-card-clickable">
            {arrItemsPlans.map((item, index) => (
              <Cardplan key={index} titlePlan={item.titlePlan} arrListPlan={item.arrListPlan} notePlan={item.notePlan} />
            ))}
          </div>
        </div>
      </section>

      {/* Banner CTA Plans */}
      <Bannerwithcta
        classBanner="section-banner-plans"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "plans"}) {
      acfPagePlans {
        repeaterDetailsPlans {
          noteDetailPlan
          repeaterListDetailPlan {
            listDetailPlan
          }
          titleDetailPlan
        }
        repeaterHeroPlans {
          introHeroPlans
        }
        textDetailsPlans
        titleDetailsPlans
        titleHeroPlans
      }
      title
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
/* Export page informations */
export default Planspage
